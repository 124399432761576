import { Environment } from '@campaign-portal/components-library';

export const environment: Environment = {

	production: true,
	version: '1.4.0-0.sha.c1485d7',

	protocol: '',
	host: '',
	port: '',
	api: 'api',
	localStorageKey: 'cp',
	configURL: `./assets/config.json?v=${(new Date()).getTime()}`,

	exchangeFolder: '/exchange/',

	title: 'Alaris Labs',
	subTitle: 'Campaign Portal',

	theme: 'default',
	themeType: 'dark',

	termsUrl: '',
	privacyUrl: '',
	supportEmail: '',

	showLink: true,
	allowRegistration: true,

	systemTZOffset: 0,
	localTime: true,

	languagesID: ['en', 'es', 'tr'],

	is2FA: false
};
